export const ThemeColors = {
    // red: '#ab1e05',
    red: '#2c4fa2',
    white: '#FFFFFF',
    black: '#000000',
    textColor: '#656565',
    textColor_fade: '#959595',
    yellow: 'yellow',
    bg_grey: '#f7f7f7',
    themeColor: '#2c4fa2',
};
